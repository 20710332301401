import React from "react";
import "./BackgroundVideo.css";

const BackgroundVideo = () => {
  const videoSource =
    "https://media-hosting-bucket.s3-us-west-2.amazonaws.com/alex-website/background.mp4";
  return (
    <div className="bg-video-container">
      <video className="bg-video" autoPlay="autoplay" loop="loop" muted>
        <source src={videoSource} type="video/mp4" />
      </video>
    </div>
  );
};

export default BackgroundVideo;
