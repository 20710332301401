import React, { useState, useEffect, useRef } from "react";

/* ---------- css ---------- */
import "bootstrap/dist/css/bootstrap.css";
// import "../LottieAnimation/node_modules/bootstrap/dist/css/bootstrap.css";
import "./Home.css";

/* ---------- images ---------- */

/* ---------- components ---------- */
import Contact from "../Contact/Contact";
import Reviews from "../Reviews/Reviews";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ImageCarouselHeader from "../ImageCarouselHeader/ImageCarouselHeader";
import SendMessageRow from "../SendMessageRow/SendMessageRow";
import ScrollHandler from "../ScrollHandler/ScrollHandler";

import workImg1 from "../../resources/bf.png";
import workImg2 from "../../resources/css.svg";
import school from "../../resources/unlv.jpeg";
import shieldImg from "../../resources/shield.png";
import { BrowserRouter } from "react-router-dom";

const Home = (props) => {
  const childReference = useRef(null);

  const scrollIntoView = () => {
    childReference.current.scrollIntoView({
      block: "center",
      inline: "center",
      behavior: "smooth",
    });
  };
  return (
    <React.Fragment>
      {/* <ScrollHandler /> */}

      <Nav />

      <ImageCarouselHeader />
      <video
        id="bg-video"
        playsinline=""
        muted=""
        autoplay=""
        loop=""
        class="bg-video"
      >
        {/* <source src="resources/script-type-vid.mp4" type="video/mp4"> */}
        {/* <source src="resources/script-type-vid.webm" type="video/webm"> */}
        {/* <source src="resources/script-type-vid.ogg" type="video/ogg"></source> */}
      </video>

      <div className="home-container">
        {/* <h2 className="reviews-title">RESUME</h2> */}

        <div className="careers-container">
          {/* START RULE OF 3 */}
          <h1 className="about-job-header">Work Experience</h1>

          <div className="position-relative overflow-hidden p-3  text-center text-light services-container">
            <div className="row">
            <div className="col-lg-12">
                <div className="category-img-container">
                  <img src={workImg1} className="category-img" />
                </div>
                <p className="about-description">
                Web Designer, UNLV OED & NV SBDC	<br></br>
                September 2021- Current <br></br>
                Currently designing website for makerspace with React. Learned and tested machines in makerspace to assist clients. Created wireframes and designed websites for small businesses with Adobe XD. Gave clients recommendations for websites to improve user experience 
                </p>
              </div>
              <div className="col-lg-12">
                <div className="category-img-container">
                  <img src={workImg2} className="category-img" />
                </div>
                <p className="about-description">
                Software Engineering Intern, CloudSurf Software <br></br>
                May 2021 - August 2021<br></br>
                Collaborated with a team of four in developing websites and mobile applications for clients using React and React Native. Created user interface and designed user experience for pre-beta financial transactions app. Utilized collaboration and software tooling including AWS, Git and Trello in production environments for all phases of the software development life cycle.
                </p>
              </div>
              
              <div className="col-lg-12">
              <h1 className="about-job-header">Education</h1>

                <div className="category-img-container">
                  <img src={school} className="category-img" />
                </div>
                <p className="about-description">
                Bachelor of Science, Computer Science  <br></br>
                Expected Fall 2022 <br></br>
                Relevant Courses: Computer Science I and II, Computer Engineering I, Data Structures, Assembly Languages, Discrete Mathematics I and II, Computer Architecture, Automata and Formal Languages, Database Management Systems.
                </p>
              </div>
              {/* <div className="col-lg-12">
                <div className="category-img-container">
                  <img src={shieldImg} className="category-img" />
                </div>        
                <h2 className="about-job-header">Strong Ethics</h2>
                <p className="about-description">
                  Feel safe working with someone who’s driven by honesty,
                  integrity, and family values. Get the genuine loan experience
                  you've been looking for.
                </p>
              </div> */}
            </div>
          </div>

          {/* <h2 className="reviews-title">REVIEWS</h2>
          <Reviews /> */}
        </div>
      </div>

      <div id="#contact-section"></div>
      <Contact />

      <Footer />
    </React.Fragment>
  );
};

export default Home;
