import React, { useState } from "react";
import "./Nav.css";
import "./hamburgers.css";
import cloudSurfLogo from "../../resources/logos/cloud-logo-black.svg";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log(pageURI);
  };

  const responsive = menuOpen ? " responsive" : "";

  const borderRadius = menuOpen ? " topnav-border-radius" : "";

  const hamburgerType = menuOpen ? " is-active" : "";

  const pageURI = window.location.pathname + window.location.search;

  return (
    <React.Fragment>
      <div className={"topnav" + responsive} id="myTopnav">
        <div className="top-strip">
          <span> </span>
          <a href="https://www.github.com/tinyantlers" target="_blank">
            <i className="fa fa-github top-strip-icon"></i>
          </a>
          <a href="https://www.linkedin.com/in/alexis-ugalde/" target="_blank">
            <i className="fa fa-linkedin top-strip-icon"></i>
          </a>
        </div>
        <a href="/" className="top-nav-brand topnav-item topnav-first">
          {/* <img className="topnav-logo" src={cloudSurfLogo} alt="" /> */}
          <span className="topnav-company-name">Alexis Ugalde</span>
        </a>
        <a href="https://www.github.com/tinyantlers" target="_blank">
          <i className="fa fa-github mobile-nav-item"></i>
        </a>
        <a href="https://www.linkedin.com/in/alexis-ugalde/" target="_blank">
          <i className="fa fa-linkedin mobile-nav-item"></i>
        </a>

        <button
          className={
            " hamburger hamburger--spin no-bs-border hamburger-icon" +
            hamburgerType
          }
          type="button"
          onClick={toggleMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </React.Fragment>
  );
};

export default Nav;
